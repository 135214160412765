import "./Home.css";
import "./Responsive.css";
import React, { Component } from "react";
import CarouselBox from "./CarouselBox.jsx";
import MyModal from "./MyModal";
import  { Redirect } from 'react-router-dom'

export class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.state = { studios: [], modal: false, exep: false };
    this.setModalReturn = this.setModalReturn.bind(this);
  }

  setModalReturn(visible) {
      this.setState({modal: visible})
  }
  
  loadData(){
    const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    };
    fetch(this.props.apiUrl, requestOptions)
      .then((response) => response.json()
      .then(data => {
      this.setState({ studios: data });
      })
    .catch (() => {
        this.setState({exep: true});
    })
  );
}
  componentDidMount() {
    this.loadData();
  }

  render() {
    if(this.state.exep){
      return <Redirect to='404' props = {this.state.exep}/>
     }
    return this.state.studios.map((studio, index) => {
      if (index % 2 === 0) {
        if(studio.isMain){
          return (
            <div
              key={index}
              id="list"
              data-project-id={studio.id}
              className="raw-return space-ar return-block"
            >
              <div className="coll-6 vert-align">
                <div className="portf margin-left">{studio.title}</div>
                <div className="text_portf margin-left with-400">
                  {studio.description}
                </div>
                <div className="button clickable" onClick={() => this.setModalReturn(true)}>Замовити зйомку</div>
                  <MyModal
              visible={this.state.modal}
              setVisible={this.setModalReturn}
              amount= {studio.title}
              key={index}
              isMain = {true}
            />
              </div>
              <div className="coll-6 vert-align">
                <div className="photo-type-1-left responsive-class photo-res">
                  <div>
                    <CarouselBox items={studio.imageUrls} key={index} />
                  </div>
                </div>
              </div>{" "}
            </div>
          )
        }
        else{
        return (
          <div
            key={index}
            id="list"
            data-project-id={studio.id}
            className="raw-return space-ar return-block"
          >
            <div className="coll-6 vert-align">
              <div className="portf margin-left">{studio.title}</div>
              <div className="text_portf margin-left with-400">
                {studio.description}
              </div>
            </div>
            <div className="coll-6 vert-align">
              <div className="photo-type-1-left responsive-class photo-res">
                <div>
                  <CarouselBox items={studio.imageUrls} key={index} />
                </div>
              </div>
            </div>{" "}
          </div>
        )};
      } else {
        if(studio.isMain){
          return (
            <div
              key={index}
              id="list"
              data-project-id={studio.id}
              className="raw-return space-ar"
            >
              <div className="coll-6 vert-align">
                <div className="portf margin-left">{studio.title}</div>
                <div className="text_portf margin-left with-400">
                  {studio.description}
                </div>
                <div className="button clickable" onClick={() => this.setModalReturn(true)}>Заказать съемку</div>
                  <MyModal
              visible={this.state.modal}
              setVisible={this.setModalReturn}
              amount= {studio.title}
              key={index}
              isMain = {true}
            />
              </div>
              <div className="coll-6 vert-align">
                <div className="photo-type-1-right responsive-class photo-res">
                  <div>
                    <CarouselBox items={studio.imageUrls} key={index} />
                  </div>
                </div>
              </div>{" "}
            </div>
          )
        }
        else{
        return (
          <div
            key={index}
            id="list"
            data-project-id={studio.id}
            className="raw-return space-ar"
          >
            <div className="coll-6 vert-align">
              <div className="portf margin-left">{studio.title}</div>
              <div className="text_portf margin-left with-400">
                {studio.description}
              </div>
            </div>
            <div className="coll-6 vert-align">
              <div className="photo-type-1-right responsive-class photo-res">
                <div>
                  <CarouselBox items={studio.imageUrls} key={index} />
                </div>
              </div>
            </div>{" "}
          </div>
        )};
      }
    });
  }
}
