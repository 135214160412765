import "./Home.css";
import "./Responsive.css";
import React, { Component } from "react";
import MyModal from "./MyModal";
import  { Redirect } from 'react-router-dom'

export class PriceList extends Component {
  constructor(props) {
    super(props);
    this.state = { prices: [], modal: false, amount: "", exep: false };

    this.setModalReturn = this.setModalReturn.bind(this);
  }
  setModalReturn(visible, value) {
    this.setState({
      modal: visible,
      amount: value,
    });
  }

  loadData(){
    const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    };
    fetch(this.props.apiUrl, requestOptions)
      .then((response) => response.json()
      .then(data => {
      this.setState({ prices: data });
      })
    .catch (() => {
        this.setState({exep:true});
    })
  );
}
  componentDidMount() {
    this.loadData();
  }

  // getTextRow(description) {
  //   return Object.values(description)
  //     .reduce((acc, descriptionset) => {
  //       return [...acc, ...descriptionset];
  //     }, [])
  //     .map((text, index) => <li key={index}>{text}</li>);
  // }

  render() {
    if(this.state.exep){
      return <Redirect to='404' props = {this.state.exep}/>
     }
    return this.state.prices.map((price, index) => {
      const { id, amount, ...description } = price;
      return (
        <div key={index} className="price-col">
          <div className="cont13">
            <div className="price portf "> {price.amount} </div>
            <div className="price-description text_portf">
              {/* <ul>{this.getTextRow(description)}</ul> */}
              <ul>
                {price.description.map((li, key) => {
                  return(
                  <li key={key}>
                    {li}
                  </li>)
                })}
              </ul>
            </div>
            <button
              className="button2"
              onClick={() => this.setModalReturn(true, price.amount)}>
              Замовити зйомку
            </button>
          </div>
          <MyModal
            visible={this.state.modal}
            setVisible={this.setModalReturn}
            amount={"за " + this.state.amount}
            isMain ={false}
            key={index}
          />
        </div>
      );
    });
  }
}
