import './Home.css';
import './Responsive.css';
import React, { Component } from 'react'
import { elementScrollIntoView, polyfill } from "seamless-scroll-polyfill";
polyfill();

export class ProjectListMain extends Component {
    constructor(props) {
        super(props)
        this.state = { studios: []}
    }
     
    loadData(){
      const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      };
      fetch(this.props.apiUrl, requestOptions)
        .then((response) => response.json()
        .then(data => {
        this.setState({ studios: data });
        })
      .catch (() => {
          this.setState({exep:true});
      })
    );
  }
  componentDidMount() {
    this.loadData();
}

  OnScroll() {
    var main = document.getElementById("main").dataset.projectIdMain;
    var element = document.querySelector('[data-project-id="' + main + '"]');
    elementScrollIntoView(element, { block: "center", behavior: "smooth" });
  }

  render() {
    return (
      <div
        className="raw-main space-ar2"
        id="main"
        data-project-id-main={this.state.studios.id}
      >
        <div className="coll-4">
          {/*<div className="portf margin-left">{this.state.studios.title}</div>*/}
            <div className="portf margin-left">Останній проект</div>
          <div className="text_portf margin-left with-400">
            {this.state.studios.description}
          </div>
          <div onClick={this.OnScroll} className="button">
            <div className="link-slide">Докладніше</div>
          </div>
        </div>
        <img className="photo-main mb" src={this.state.studios.imageUrls}></img>
      </div>
    );
  }
}
