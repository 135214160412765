import "./Home.css";
import "./Responsive.css";
import React, { Component } from "react";
import  { Redirect } from 'react-router-dom'

export class PhotoList extends Component {
  constructor(props) {
    super(props);
    this.state = { studios: [], exep: false};
  }

  loadData(){
    const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    };
    fetch(this.props.apiUrl, requestOptions)
      .then((response) => response.json()
      .then(data => {
      this.setState({ studios: data });
      })
    .catch (() => {
        this.setState({exep:true});
    })
  );
}

componentDidMount() {
  this.loadData();
}

  render() {
    if(this.state.exep){
      return <Redirect to='404' props = {this.state.exep}/>
     }
    return this.state.studios.map((studio, index) => {
      if (index % 2 === 0) {
        return (
          <div key={index} className="raw-return space-ar return-block">
            <div className="coll-6 vert-align">
              <div className="portf margin-left">{studio.name}</div>
              <div className="text_portf margin-left with-400">
                <ul>
                  <li><b>Адреса</b></li>
                  <li>{studio.address}</li>
                  <li><b>Сайт</b></li>
                  <li><a rel="nofollow" href={studio.link} target="_blank" className="react-link">{studio.link}</a></li>
{/*                  <li><b>Рекомендую залы</b></li>
                  <li>{studio.recomend}</li>*/}
                </ul>
              </div>
            </div>
            <div className="coll-6 vert-align">
              <div className="photo-type-1-left responsive-class photo-res">
                <div>
                  <img className="photo-main2" src={studio.imageUrl}/>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div key={index} className="raw-return space-ar">
            <div className="coll-6 vert-align">
              <div className="portf margin-left">{studio.name}</div>
              <div className="text_portf margin-left with-400">
                <ul>
                  <li><b>Адреса</b></li>
                  <li>{studio.address}</li>
                  <li><b>Сайт</b></li>
                  <li><a rel="nofollow" href={studio.link} target="_blank" className="react-link">{studio.link}</a></li>
                  {/*<li><b>Рекомендую залы</b></li>
                  <li>{studio.recomend}</li>*/}
                </ul>
              </div>
            </div>
            <div className="coll-6 vert-align">
              <div className="photo-type-1-right responsive-class photo-res">
                <div>
                  <img className="photo-main2" src={studio.imageUrl}/>
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  }
}
