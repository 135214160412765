import React, { Component } from "react";
import { NavItem, Nav } from "react-bootstrap";
import "./NavBar.css";

export class NavBar extends Component {
  render() {
    return (
      <div className="Nav">
        <header>
          <div className="nav-bar">
            <div className="cell-cont">
              <Nav.Item className="cell">
                <Nav.Link eventKey="1" href="/portfolio" bsPrefix=" ">
                  ПОРТФОЛІО
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="cell">
                <Nav.Link eventKey="2" href="/project" bsPrefix=" ">
                  ПРОЕКТИ
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="cell">
                <Nav.Link eventKey="3" href="/price" bsPrefix=" ">
                  ВАРТІСТЬ
                </Nav.Link>
              </Nav.Item>
            </div>

            <div className="cont_for_logo">
              <NavItem className="logo">
                <Nav.Link eventKey="4" href="/" bsPrefix=" ">
                  <div className="logo_center">
                    <div className="logo_upper">PHOTOS</div>
                    <div className="logo_bottom"> BY STARSHOVA</div>
                  </div>
                </Nav.Link>
              </NavItem>
            </div>

            <div className="cell-cont2">
              <Nav.Item className="cell">
                <Nav.Link eventKey="5" href="/studios" bsPrefix=" ">
                  ФОТОСТУДІЇ
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="cell">
                <Nav.Link eventKey="6" href="/forms" bsPrefix=" ">
                  ОБРАЗИ
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="cell">
                <Nav.Link eventKey="7" href="/contacts" bsPrefix=" ">
                  КОНТАКТИ
                </Nav.Link>
              </Nav.Item>
            </div>
          </div>
        </header>
      </div>
    );
  }
}
