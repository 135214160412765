import React, { Component } from "react";
import "./Home.css";
import "./Responsive.css";
import CarouselBox from "./CarouselBox.jsx";

export class PortfolioItem extends Component {
  constructor(props) {
    super(props);
    this.state = { data: props.imageUrls };
  }

  render() {
    return (
      <div className="space-ar">
        <div className={this.state.data.className}>
          {" "}
          <CarouselBox
            items={this.state.data.imageUrls}
            main={this.state.data.isMain}
          />
        </div>
      </div>
    );
  }
}
