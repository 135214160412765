import React from "react";
import "./Home.css";
import "./Responsive.css";

export function Footer() {
  return (
    <footer>
      <div className="roww space-ar foot">
        <div className="coll-6 coll-media">
          <div className="media">
            <div className="facebook"><a className="react-link" href="https://www.facebook.com/starshova.net.ua" target='_blank'>Facebook</a></div>
            <div className="insta"><a className="react-link" href="https://www.instagram.com/starshova_oksana_photographer" target="_blank">Instagram</a></div>
            <div className="youtube"><a className="react-link" href="https://www.youtube.com/channel/UCu5nd_27KAOSD6KfB-US6qA" target="_blank">YouTube</a></div>
          </div>
          <div className="water-mark">PhotosByStarshova 2011-{new Date().getFullYear()}</div>
        </div>
        <div className="coll-6 coll-media">
          <div className="block-number">
            <div className="number">+ 38 0977654489</div>
            <div className="politic">
              Політика обробки персональних даних
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
