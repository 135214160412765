import React, { Component } from "react";
import { PriceList } from "./PriceList";
import { Footer } from "./Footer";
import WOW from "wowjs";
import MetaTags from "react-meta-tags";

export class Price extends Component {
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }

  render() {
    return (
      <div className="wrapper">
        <MetaTags>
          <title>Оксана Старшова фотограф Київ - Вартість</title>
          <meta name="description" content="Оксана Старшова фотограф Київ - Вартість" />
          <meta property="og:title" content="Оксана Старшова фотограф Київ - Вартість" />
          <meta property="og:image" content="path/to/image.jpg" />
        </MetaTags>

        <div className="main_screen2">
          <div className="roww align-center space-ar2">
            <div className="coll-4">
              <div className="portf margin-left">Вартість</div>
              <div className="text_portf margin-left with-400">
                Вартість моєї роботи 4500 грн. за годину. Також є пакетні зйомки. У вартість всіх пакетів входить організація зйомки - пошук та бронь студії, підбір образів з вашого одягу, підбір майстрів зачіски та мейку.
              </div>
            </div>
            <img
              className="photo-main screen_1 animate__animated animate__fadeInUp wow animated"
              src="/img/price.jpg"
            ></img>
          </div>

          <div className="roww space-ar inline-flex">
            <img
              className="arrow animate__animated animate__slideOutDown animate__infinite"
              src="/img/iconmonstr-angel-down-thin.svg"
            ></img>
            <div id="price-cont">
              <PriceList apiUrl="api/price" />
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}
