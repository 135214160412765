import React, { Component } from "react";
import { PhotoList } from "./PhotoList";
import { Footer } from "./Footer";
import WOW from "wowjs";
import MetaTags from "react-meta-tags";

export class PhotoStudio extends Component {
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }

  render() {
    return (
      <div className="wrapper">
        <MetaTags>
          <title>Оксана Старшова фотограф Київ - Фотостудії</title>
          <meta name="description" content="Оксана Старшова фотограф Київ - Фотостудії" />
          <meta property="og:title" content="Оксана Старшова фотограф Київ - Фотостудії" />
          <meta property="og:image" content="path/to/image.jpg" />
        </MetaTags>

        <div className="main_screen2">
          <img
            className="arrow animate__animated animate__slideOutDown animate__infinite"
            src="/img/iconmonstr-angel-down-thin.svg"
          ></img>
          <div className="roww align-center space-ar2">
            <div className="coll-4">
              <div className="portf margin-left">Студії Києва</div>
              <div className="text_portf margin-left with-400">
                У Києві понад сотню фотостудій. І щоб Вам легше було визначитися з місцем зйомки, я зробила список студій з чудовим світлом та гарними інтер'єрами.
              </div>
            </div>
            <img
              className="photo-main screen_1 animate__animated animate__fadeInUp wow animated"
              src="/img/studio/0.jpg"
            ></img>
          </div>

          <div className="text-block space-ar">
            <div className="coll-9">
              <div className="portf">Фотостудії, які я пропоную</div>
              <div className="text_portf with-larger-block">
                Я відібрала студії в яких через специфіку світла, колірної гами та інтер'єру, ми отримаємо результат, як у моєму портфоліо. Вам потрібно лише вибрати, що ближче для вас за настроєм та по ТЗ зйомки.
              </div>
            </div>
          </div>

          <div>
            <PhotoList apiUrl="api/photos" />
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}
