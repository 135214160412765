import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import WOW from "wowjs";
import Modal from "react-bootstrap/Modal";

function CarouselBox(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  React.useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  let data = [];
  data = props.items;
  let main = "sm";
  if (props.main) {
    main = "xl";
  }

  if (props.items) {
    return (
      <div>
        <Carousel className="animate__animated animate__fadeInUp wow animated img-fluid clickable">
          {data.map((index) => {
            return (
              <Carousel.Item key={index}>
                <img
                  className="d-block slider-main photo-main2"
                  src={index}
                  onClick={handleShow}
                />

                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
        <>
          <Modal onHide={handleClose} show={show} keyboard={false} size={main}>
            <Modal.Header closeButton className="heading"></Modal.Header>
            <Modal.Body>
              <Carousel>
                {data.map((index) => {
                  return (
                    <Carousel.Item key={index + "mw"}>
                      <img
                        className="d-block slider-main photo-main2"
                        src={index}
                      />

                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </Modal.Body>
          </Modal>
        </>
      </div>
    );
  } else return <div></div>;
}

export default CarouselBox;
