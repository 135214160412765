import React, { Component } from "react";
import { PortfolioList } from "./PortfolioList";
import { Footer } from "./Footer";
import WOW from "wowjs";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";

export class Portfolio extends Component {
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }

  render() {
    return (
      <div className="wrapper">
        <MetaTags>
          <title>Оксана Старшова фотограф Київ - Портфоліо</title>
          <meta name="description" content="Оксана Старшова фотограф Київ - Портфоліо" />
          <meta property="og:title" content="Оксана Старшова фотограф Київ - Портфоліо" />
          <meta property="og:image" content="/img/portfolio/pf0.jpg" />
          <meta property="og:title" content="Фотограф Старшова Оксана Київ - Портфоліо"/>
          <meta property="og:image" content="https://starshova.net.ua/img/portfolio/pf0.jpg"/>
        </MetaTags>

        <div className="main_screen2">
          <img
            className="arrow animate__animated animate__slideOutDown animate__infinite"
            src="/img/iconmonstr-angel-down-thin.svg"
          ></img>
          <div className="roww align-center space-ar2">
            <div className="coll-4">
              <div className="portf margin-left">Моє портфоліо</div>
              <div className="text_portf margin-left with-400">
                Головна героїня моїх зйомок - дівчина та її світ. Вона із сім'єю, вона з коханим, вона з дітьми, вона на роботі. Жанри - психологічний портрет, бізнес-портрет, фешн, будуар, лавстайл, контент для соц. мереж.
              </div>
              <div className="button">
                <a href="/price/#price-cont">
                  <div>Замовити зйомку</div>
                </a>
              </div>
            </div>
            <img
              className="photo-main screen_1 animate__animated animate__fadeInUp wow animated"
              src="../img/portfolio/pf0.jpg"
            ></img>
          </div>

          <div className="text-block space-ar">
            <div className="coll-9">
              <div className="portf">Мої роботы</div>
              <div className="text_portf with-larger-block">
                У цьому розділі лише частково показані мої роботи. Більше можна переглянути на сторінці <Link className="react-link" to='/project'>“Проекти”</Link> и <Link className="react-link" to='/forms'>"Образи"</Link>. 
                А також у <a className="react-link" href="https://www.facebook.com/starshova.net.ua" target='_blank'>Facebook</a> и <a className="react-link" href="https://www.instagram.com/starshova_oksana_photographer" target="_blank">Instagram</a>. 
                Буду рада, якщо перегляд принесе Вам задоволення)
              </div>
            </div>
          </div>

          <div>
            <PortfolioList apiUrl="api/portfolio" />
          </div>
         
        </div>
      </div>
    );
  }
}
