import React, { Component } from "react";
import { PortfolioItem } from "./PortfolioItem";
import  { Redirect } from 'react-router-dom'

export class PortfolioList extends Component {
  constructor(props) {
    super(props);
    this.state = { imgs: [], exep : false };
  }

  loadData(){
    const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    };
    fetch(this.props.apiUrl, requestOptions)
      .then((response) => response.json()
      .then(data => {
      this.setState({ imgs: data });
      })
    .catch (() => {
        this.setState({exep:true});
    })
  );
}
  componentDidMount() {
    this.loadData();
  }

  render() {
    if(this.state.exep){
     return <Redirect to='404' props = {this.state.exep}/>
    }
    return this.state.imgs.map((img, index) => {
      if (img.isMain) {
        return <PortfolioItem imageUrls={img} key={index + "main"} />;
      } else {
        return (
          <div className="coll-6" key={index}>
            <div className="space-ar">
              <PortfolioItem imageUrls={img} />
            </div>
          </div>
        );
      }
    });
  }
}
