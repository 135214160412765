import React from "react";
import "./Home.css";
import "./Responsive.css";
import  { Redirect } from 'react-router-dom'


export class PriceInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      num: "",
      succed: false,
      exep: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeNum = this.handleChangeNum.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.numCheck = this.numCheck.bind(this);
    this.changeNum = this.changeNum.bind(this);
    this.formatPhoneNum = this.formatPhoneNum.bind(this)
  }

  numCheck(e) {
    if (e.target.name == "phone" && this.state.num == ""){
      this.setState({num: "+380"})
    }
  }

  formatPhoneNum(value){
    if (!value)
       return value; 
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 3) 
      return `+${phoneNumber}`;
      if (phoneNumberLength < 6) 
      return `+${phoneNumber}`;
    if (phoneNumberLength < 9) {
      return `+${phoneNumber.slice(0, 2)}(${phoneNumber.slice(2, 5)})${phoneNumber.slice(5, 8)}`;
    }
    return `+${phoneNumber.slice(0, 2)}(${phoneNumber.slice(2, 5)})${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`;
    }

  changeNum(e){
    const formatedPhoneNum = this.formatPhoneNum(e.target.value);
    this.setState({num: formatedPhoneNum});
  }


  handleChange(event) {
    this.setState({ name: event.target.value });
  }

  handleChangeNum(event) {
    this.setState({ num: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: this.state.name,
        num: this.state.num,
        amount: this.props.money,
        isMain: this.props.isMain,
      }),
    };
    try{fetch("api/order", requestOptions).then((response) => response.json().then(this.setState({succed: true})))}
    catch(e){this.setState({exep: true})};
  }

  render() {
    if(this.state.succed)
      return <Redirect to='success' props = {this.state.succed}/>

    if(this.state.exep)
      return <Redirect to='404' props = {this.state.exep}/>

    return (
      <form onSubmit={this.handleSubmit}>
        <label htmlFor="name"></label>
        <input
          type="text"
          id="name"
          placeholder=" Ваше имя"
          required
          name={this.state.name}
          onChange={this.handleChange}
        />
        <label htmlFor="phone"></label>
        <input
          type="tel"
          name="phone"
          id="phone"
          placeholder=" Номер телефона"
          required
          minLength={16}
          value={this.state.num}
          onChange={(e) => this.changeNum(e)}
          onClick = {e => this.numCheck(e)}
        />
        <label htmlFor="text"></label>
        <button type="submit" id="button3">
          Отправить
        </button>
      </form>
    );
  }
}
