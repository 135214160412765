import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Portfolio } from './components/Portfolio';
import { Project } from './components/Project';
import { Contacts } from './components/Contacts';
import { FormsPage } from './components/FormsPage';
import { Price } from './components/Price';
import { PhotoStudio } from './components/PhotoStudio';
import {ExeptionCon} from './components/ExeptionCon';
import {Success} from './components/Success';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route path='/studios' component={PhotoStudio} />
        <Route exact path='/' component={Home} />
        <Route path='/portfolio' component={Portfolio} />
        <Route path='/project' component={Project} />
        <Route path='/contacts' component={Contacts} />
        <Route path='/forms' component={FormsPage} />
        <Route path='/price' component={Price} />
        <Route path='/404' component={ExeptionCon} />
        <Route path='/success' component={Success} />
      </Layout>
    );
  }
}
