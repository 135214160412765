import React from "react";

export function Success (){
        return (
            <div className="wrapper">
                <div className="container_back">
                    <div className="main-header">
                        <h3>Success!</h3>
                        <h4>операция прошла успешно</h4>
                    </div>
                </div>
            </div>
        );
}
