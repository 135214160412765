import "./Home.css";
import "./Responsive.css";
import React, { Component } from "react";
import { ContactsInput } from "./ContactsInput";
import WOW from "wowjs";
import MetaTags from "react-meta-tags";

export class Contacts extends Component {
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }

  render() {
    return (
      <div className="wrapper">
        <MetaTags>
          <title>Оксана Старшова фотограф Київ - Контакти</title>
          <meta name="description" content="Оксана Старшова фотограф Київ - Контакти" />
          <meta property="og:title" content="Оксана Старшова фотограф Київ - Контакти" />
          <meta property="og:image" content="/img/contact/c0.jpg" />
        </MetaTags>

        <div className="main_screen2">
          <div className="roww align-center space-ar2">
            <div className="coll-6">
              <div className="portf margin-left ">Зв'яжіться зі мною</div>
              <div className="text_portf margin-left with-400 contact-hieght">
                <p>
                  Заповнивши цю форму, або у будь-якому месенджері за номером <a href="tel:+380977654489">097 765 44 89</a>.
                </p>
                <ContactsInput />
              </div>
            </div>
            <img className="photo-main screen_1 animate__animated animate__fadeInUp wow animated" src="/img/contact/c0.jpg"></img>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}
