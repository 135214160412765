import React, { Component } from "react";
import WOW from "wowjs";
import "./Home.css";
import "./Responsive.css";
import  { Redirect } from 'react-router-dom'

export class FormsList extends Component {
  constructor(props) {
    super(props);
    this.state = { studios: [], exep: false};
  }

  loadData(){
    const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    };
    fetch(this.props.apiUrl, requestOptions)
      .then((response) => response.json()
      .then(data => {
      this.setState({ studios: data });
      })
    .catch (() => {
        this.setState({exep:true});
    })
  );
}

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    new WOW.WOW({
      live: false,
    }).init();
  }

  parityCheck(index) {
    var row = 0;

    if (index + 1 <= 2) {
      return true;
    }

    if ((index + 1) % 2 == 0) {
      row = Math.trunc(index / 2);
      return row % 2 == 0;
    } else {
      row = Math.trunc((index + 1) / 2);
      return row % 2 == 0;
    }
  }

  render() {
    if(this.state.exep){
      return <Redirect to='404' props = {this.state.exep}/>
     }
    return this.state.studios.map((studio, index) => {
      if (this.parityCheck(index)) {
        if ((index + 1) % 2 == 0) {
          return (
            <div key={index} className="coll-6 vert-align">
              <div className="photo-type-2-right space-ar">
                <div>
                  <img
                    className="inherit animate__animated animate__fadeInUp wow"
                    src={studio.imageUrl}
                  ></img>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div key={index} className="coll-6 vert-align">
              <div className="photo-type-1-left space-ar">
                <div>
                  <img
                    className="inherit animate__animated animate__fadeInUp wow"
                    src={studio.imageUrl}
                  ></img>
                </div>
              </div>
            </div>
          );
        }
      } else {
        if ((index + 1) % 2 == 0) {
          return (
            <div key={index} className="coll-6 vert-align">
              <div className="photo-type-1-right space-ar">
                <div>
                  <img
                    className="inherit animate__animated animate__fadeInUp wow"
                    src={studio.imageUrl}
                  ></img>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div key={index} className="coll-6 vert-align">
              <div className="photo-type-2-left space-ar">
                <div>
                  <img
                    className="inherit animate__animated animate__fadeInUp wow"
                    src={studio.imageUrl}
                  ></img>
                </div>
              </div>
            </div>
          );
        }
      }
    });
  }
}
