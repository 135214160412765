import React, { Component } from 'react';
import { FormsList } from './FormsList';
import { Footer } from './Footer';
import './Responsive.css';
import WOW from 'wowjs';
import MetaTags from 'react-meta-tags';

export class FormsPage extends Component {
  componentDidMount() {
    new WOW.WOW({
      live: false
  }).init();
}

  render() {
    return (
      <div className="wrapper">
          <MetaTags>
            <title>Оксана Старшова фотограф Київ - Образи</title>
            <meta name="description" content="Оксана Старшова фотограф Київ. Образи, сукні" />
            <meta property="og:title" content="Оксана Старшова фотограф Киев - Образи" />
            <meta property="og:image" content="/img/forms/1.jpg" />
          </MetaTags>
        <div className="main_screen2">
          <img className="arrow animate__animated animate__slideOutDown animate__infinite" src="/img/iconmonstr-angel-down-thin.svg"></img>
          <div className="roww align-center space-ar2">
            <div className="coll-4">
              <div className="portf margin-left">Готові образи</div>
              <div className="text_portf margin-left with-400">
                  Щоб спростити підготовку до зйомки, я зібрала безліч образів, які чудово виглядають у кадрі.
              </div>
            </div>
            <img
              className="photo-main screen_1 animate__animated animate__fadeInUp wow animated"
              src="/img/forms/0.jpg"
            ></img>
          </div>
          <div className="text-block space-ar">
            <div className="coll-9">
                <div className="portf">
                    Моя колекція одягу
                </div>
                <div className="text_portf with-larger-block">
                    Більшість нарядів підходять на S та М розміри. Окремо є одяг розміру L. Вибираючи собі образ, враховуйте особливості своєї фігури та типу зовнішності. Усі представлені образи доступні для оренди. Більше моделей у <a className="react-link" href="https://www.instagram.com/oxanas_dress/" target="_blank">Instagram акаунті</a>
                </div>
            </div>  
        </div>
            <div><FormsList apiUrl = 'api/forms'/></div>    
             {/* <Footer/> */}
          </div>
      </div>
    )
  }
}
