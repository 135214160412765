import React, { Component } from "react";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer";

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <NavBar />
        {this.props.children}
        <Footer/>
      </div>
    );
  }
}
