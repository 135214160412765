import React from "react";

export function ExeptionCon (){
        return (
            <div className="wrapper">
                <div className="container_back">
                    <div className="main-header">
                        <h3>Something Went Wrong</h3>
                        <h4>приносим свои извинения</h4>
                    </div>
                </div>
            </div>
        );
}
 