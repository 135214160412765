import React, { Component } from "react";
import { ProjectList } from "./ProjectList";
import { ProjectListMain } from "./ProjectListMain";
import { Footer } from "./Footer";
import MetaTags from "react-meta-tags";

export class Project extends Component {
  render() {
    return (
      <div className="wrapper">
        <MetaTags>
          <title>Оксана Старшова фотограф Київ - Проекти</title>
          <meta name="description" content="Оксана Старшова фотограф Київ - Проекти" />
          <meta property="og:title" content="Оксана Старшова фотограф Київ - Проекти" />
          <meta property="og:image" content="/img/project/7-1.jpg" />
        </MetaTags>

        <div className="main_screen2">
          <div className="arrow-jacket">
            <img
              className="arrow animate__animated animate__slideOutDown animate__infinite"
              src="/img/iconmonstr-angel-down-thin.svg"
            ></img>
          </div>
          <ProjectListMain apiUrl="api/projects/getmain" />
          <div>
            <ProjectList apiUrl="api/projects" />
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}
