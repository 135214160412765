import React from "react";
import "./Home.css";
import "./Responsive.css";
import  { Redirect } from 'react-router-dom'

export class ContactsInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      num: "",
      question: "",
      checked: true,
      disabled: true,
      succed: false,
      exep: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeNum = this.handleChangeNum.bind(this);
    this.handleChangeQuestion = this.handleChangeQuestion.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAgree = this.handleAgree.bind(this);
    this.numCheck = this.numCheck.bind(this);
    this.changeNum = this.changeNum.bind(this);
    this.formatPhoneNum = this.formatPhoneNum.bind(this)
  }

  numCheck(e) {
    if (e.target.name == "phone" && this.state.num == ""){
      this.setState({num: "+380"})
    }
  }

  formatPhoneNum(value){
    if (!value)
       return value; 
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 3) 
      return `+${phoneNumber}`;
      if (phoneNumberLength < 6) 
      return `+${phoneNumber}`;
    if (phoneNumberLength < 9) {
      return `+${phoneNumber.slice(0, 2)}(${phoneNumber.slice(2, 5)})${phoneNumber.slice(5, 8)}`;
    }
    return `+${phoneNumber.slice(0, 2)}(${phoneNumber.slice(2, 5)})${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`;
    }

  changeNum(e){
    const formatedPhoneNum = this.formatPhoneNum(e.target.value);
    this.setState({num: formatedPhoneNum});
  }


  handleAgree(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({ disabled: !value });
  }

  handleChange(event) {
    this.setState({ name: event.target.value });
  }

  handleChangeNum(event) {
    this.setState({ num: event.target.value })
  }

  handleChangeQuestion(event) {
    this.setState({ question: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: this.state.name,
        num: this.state.num,
        question: this.state.question,
      }),
    };
    try{fetch("api/contact", requestOptions).then((response) => response.json().then(this.setState({succed: true})))}
    catch(e){this.setState({exep: true})};
  }

  render() {
    if(this.state.succed)
      return <Redirect to='success' props = {this.state.succed}/>

    if(this.state.exep)
      return <Redirect to='404' props = {this.state.exep}/>
     
    return (
      <form id="contact-form" onSubmit={this.handleSubmit}>
        <label htmlFor="name"></label>
        <input
          type="text"
          id="name"
          placeholder=" Ваше ім'я"
          required
          name={this.state.name}
          onChange={this.handleChange}
        />

        <label htmlFor="phone"></label>
        <input
          type="tel"
          name="phone"
          id="phone"
          placeholder=" Номер телефону"
          required
          minLength={16}
          value={this.state.num}
          num={this.state.num}
          onChange={(e) => this.changeNum(e)}
          onClick = {e => this.numCheck(e)}
        />
        <label htmlFor="text"></label>
        <input
          type="text"
          name="question"
          id="question"
          placeholder=" Текст"
          required
          question={this.state.question}
          onChange={this.handleChangeQuestion}
        />

        <p>
          {" "}
          <input
            type="checkbox"
            className="custom-checkbox"
            name="check"
            value=""
            onChange={this.handleAgree}
          />
          <label htmlFor="checkbox" id="agree">
            Згода на обробку персональних даних
          </label>
        </p>
        <button type="submit" id="submit2" disabled={this.state.disabled}>
          Надіслати
        </button>
      </form>
    );
  }
}
