import React from "react";
import sad from "./Modal.module.css";
import { PriceInput } from "./PriceInput";

function MyModal(props) {
  const rootClasses = [sad.MyModal];
  if (props.visible) {
    rootClasses.push(sad.active);
  }

  return (
    <div
      className={rootClasses.join(" ")}
      onClick={() => props.setVisible(false)}
    >
      <div className={sad.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={sad.modalPrice}>Заказ сьемки {props.amount}</div>
        <PriceInput money = {props.amount} isMain = {props.isMain}/>
      </div>
    </div>
  );
}

export default MyModal;
